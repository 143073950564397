<template>
  <!-- prettier-ignore -->
  <index-content>
    <template #options>
      <n-button ghost custom-icon="fa fa-cog" text="template" @on-click="doTemplate" />
    </template>
    <search-condition searchable :loading="searching" @do-search="search">
      <ns-company v-model="condition.companyId" />
      <n-field-enum v-model="condition.status" enum-name="AdminNoticeStatus" label="status" all-option />
      <n-field-datetime v-model="condition.from" label="from" />
      <n-field-datetime v-model="condition.to" label="to" />
      <n-field-enum v-model="condition.domain" enum-name="AdminNoticeDomain" label="domain" all-option />
      <n-field-select v-model="condition.type" :options="noticeTypes" label="type" all-option />
      <n-field-enum v-model="condition.level" enum-name="AdminNoticeLevel" label="level" all-option />
    </search-condition>
    <n-table :total="total" :page-no="pageNo" :page-size="pageSize" :searched="searched" :searching="searching" :has-result="hasResult" @on-page-no="onPageNo" @on-page-size="onPageSize">
      <table class="table table-bordered table-hover table-striped text-center n-table">
        <thead>
          <tr>
            <th class="n-table-id">{{ $t('label.id') }}</th>
            <th class="col-weight-1">{{ $t('label.company') }}</th>
            <th class="col-weight-1">{{ $t('label.domain') }}</th>
            <th class="col-weight-1">{{ $t('label.level') }}</th>
            <th class="col-weight-2">{{ $t('label.type') }}</th>
            <th class="col-weight-4">{{ $t('label.subject') }}</th>
            <th class="n-table-timestamp">{{ $t('label.time') }}</th>
            <th class="action-detail">{{ $t('label.action') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in records" :key="row.id">
            <td>{{ row.id }}</td>
            <td v-selection:[`company.company`]="row.companyId"></td>
            <td v-enums:AdminNoticeDomain="row.domain"></td>
            <td v-enums:AdminNoticeLevel="row.level"></td>
            <td v-enums:AdminNoticeType="row.type"></td>
            <td class="n-ellipsis" :title="row.subject">{{ row.subject | hyphen }}</td>
            <td>{{ row.issueTime | datetime }}</td>
            <td class="n-button-box">
              <nb-custom type="info" text="detail" @on-click="openModal(row)" />
            </td>
          </tr>
        </tbody>
      </table>
    </n-table>
  </index-content>
</template>

<script>
import OperatorView from './view';
import ModalNotice from './ModalNotice';
import { COLOR } from '@/constant/color';
import PaginateMixin from '@/mixins/paginate-mixin';
import { fetch } from '@/api/operator/operator-notice';

export default OperatorView.extend({
  name: 'OperatorNotice',
  mixins: [PaginateMixin],
  data() {
    return {
      condition: {
        companyId: null,
        status: 0,
        from: this.oneWeekAgo,
        to: this.eod,
        domain: 0,
        type: 0,
        level: 0,
      },
      COLOR,
    };
  },
  methods: {
    doSearch(params) {
      let p = { ...params, ...this.condition };
      return fetch(p);
    },
    parse(rows) {
      this.records = rows.records;
    },
    openModal(model = {}) {
      this.createModal(ModalNotice, { model, on: this });
    },
    doTemplate() {
      this.pushMenu({ url: `/operator/notice/templates` });
    },
  },
});
</script>

<style lang="scss" scoped>
.n-table {
  min-width: 1300px;
}
</style>
