<template>
  <!-- prettier-ignore -->
  <n-modal v-bind="$props" detail>
    <Form ref="modalForm" :model="model" :label-width="labelWidth" class="n-modal-container" @submit.native.prevent>
      <n-row-col2>
        <n-field-id field-name="id" :value="`${model.id}`" label="id" disabled />
        <n-field-enum field-name="type" :value="model.type" enum-name="AdminNoticeType" label="type" disabled />
      </n-row-col2>
      <n-row-col2>
        <ns-company :value="model.companyId" disabled />
        <n-field-enum field-name="level" :value="model.level" enum-name="AdminNoticeLevel" label="level" disabled />
      </n-row-col2>
      <n-row-col2>
        <n-field-id :value="model.sourceId" label="sourceId" disabled />
        <n-field-enum field-name="status" :value="model.status" enum-name="AdminNoticeStatus" label="status" disabled />
      </n-row-col2>
      <n-row-col2>
        <n-field-id field-name="contextId" :value="model.contextId" label="contextId" disabled />
        <n-field-enum field-name="domain" :value="model.domain" enum-name="AdminNoticeDomain" label="domain" disabled />
      </n-row-col2>
      <n-row-col2>
        <n-field-id field-name="templateId" :value="model.templateId" label="templateId" disabled />
        <n-field-datetime field-name="issueTime" :value="model.issueTime" label="issueTime" disabled />
      </n-row-col2>
      <n-row-col1>
        <n-field-text field-name="subject" :value="model.subject" label="noticeSubject" disabled />
      </n-row-col1>
      <n-row-col1>
        <n-field-text field-name="comment" :value="model.comment" label="noticeComment" disabled />
      </n-row-col1>
    </Form>
  </n-modal>
</template>

<script>
import NModal from '@/components/common/NModal';

export default NModal.extend({
  name: 'ModalNotice',
  components: { NModal },
});
</script>
